import React, { useState } from "react";
import { useGutContext } from "../context/appContext";
import ErrorMessage from "../../../containers/errorMessage";
// import { apiUrl } from "../config/config.js";
import axios from "axios";
import { apiUrl } from "../../../config/config";

function Email({ onNext, onClick }) {
  const [email, setEmail] = useState("");

  const {
    payload,
    setPayload,
    currentPage,
    setCurrentPage,
    errorMessage,
    setErrorMessage,
    sessionValue,
  } = useGutContext();

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      setErrorMessage(
        "Invalid email address. Valid e-mail can contain only Latin letters, numbers, '@' and '.'"
      );
      return;
    }
    if (validateEmail(email)) {
      setErrorMessage(null);
      onNext(email);

      const newPayload = { ...payload, email: email };
      let data = {
        sessionId: sessionValue,
        formData: newPayload,
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiUrl}/api/v1/gut/session/update`,
        headers: {},
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // Handle successful response
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setErrorMessage("Please enter your email.");
    }
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setErrorMessage(null);
    // if (!validateEmail(value)) {
    //   setErrorMessage(
    //     "Invalid email address. Valid e-mail can contain only Latin letters, numbers, '@' and '.'"
    //   );
    // } else {
    //   setErrorMessage(null);
    // }
  };

  // console.warn("sessionValue>>", sessionValue);

  return (
    <div className="outside-container">
      <div className="question-container">
        <form onSubmit={handleSubmit}>
          <div className="question-form">
            <div className="form-group">
              <ErrorMessage>{errorMessage}</ErrorMessage>
              <div className="question">
                <p>
                  Please leave your email below where we can mail you your
                  weight & waist assessment,
                </p>
              </div>
              <div className="input-container">
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  placeholder="Your Email"
                  className="input border-b focus:border-green-900"
                  autoFocus={true}
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <div className="button-container">
                <button type="submit">Continue</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Email;
