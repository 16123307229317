import React, { useEffect, useState } from "react";
import { useGutContext } from "../context/appContext";
import RadioInput from "../../../containers/input.radio";
import ContainerWithTitle from "../../../containers/title.container";
import ButtonContainer from "../../../containers/button";
import ErrorMessage from "../../../containers/errorMessage";

const QuestionFive = ({ onNext, onPrev }) => {
  const {
    payload,
    setPayload,
    currentPage,
    setCurrentPage,
    errorMessage,
    setErrorMessage,
  } = useGutContext();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(payload?.exercise || "");
  }, [currentPage, payload]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value) {
      onNext(value);
    } else {
      setErrorMessage("Please select an option.");
    }
  };

  const handleRadioChange = (event) => {
    setErrorMessage(null);
    setValue(event.target.value);
    onNext(event.target.value);
  };

  return (
    <ContainerWithTitle
      title="How many times in a day, do you pass stool?"
      // title2="Regular physical exercise means at least 30 min of continuous walk or exercise."
      handleBack={onPrev}
      isContinueButton={false}
    >
      <div className="radio-container flex flex-col">
        <RadioInput
          label="Once in 2 days"
          value="Once in 2 days"
          colorChecked={value === "Once in 2 days"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="Once a day"
          value="Once a day"
          colorChecked={value === "Once a day"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="Twice a day"
          value="Twice a day"
          colorChecked={value === "Twice a day"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="Three or more times in a day"
          value="Three or more times in a day"
          colorChecked={value === "Three or more times in a day"}
          onChange={handleRadioChange}
        />
      </div>
      {/* <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer> */}
    </ContainerWithTitle>
  );
};

export default QuestionFive;
