import React, { useState, useEffect } from "react";
import "./home.css";
import { apiUrl } from "../../../config/config";
import { useGutContext } from "../context/appContext";
import axios from "axios";
import ContainerWithTitle from "../../../containers/title.container";

function Height({ onNext, onPrev }) {
  const { payload, setPayload, sessionValue, currentPage, setCurrentPage } =
    useGutContext();

  const [feet, setFeet] = useState("");
  const [inches, setInches] = useState("");
  const [centimeters, setCentimeters] = useState("");

  // Function to convert feet and inches to centimeters
  const convertToCentimeters = (feet, inches) => {
    const totalInches = parseInt(feet || 0) * 12 + parseInt(inches || 0); // Convert feet to inches and add
    const heightInCm = totalInches * 2.54; // Convert inches to centimeters
    return heightInCm.toFixed(2); // Keep two decimal places for precision
  };

  // Update centimeters when feet or inches change
  useEffect(() => {
    if (feet || inches) {
      const heightInCm = convertToCentimeters(feet, inches);
      setCentimeters(heightInCm);
    } else {
      setCentimeters("");
    }
  }, [feet, inches]);

  // Handle the change in the feet dropdown
  const handleFeetChange = (e) => {
    setFeet(e.target.value);
  };

  // Handle the change in the inches dropdown
  const handleInchesChange = (e) => {
    setInches(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const roundedCentimeters = Math.floor(centimeters);
    if (roundedCentimeters) {
      onNext(roundedCentimeters); // Pass the calculated height to the next step

      const newPayload = { ...payload, height: roundedCentimeters }; // Send the centimeters value
      let data = {
        sessionId: sessionValue,
        formData: newPayload,
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiUrl}/api/v1/gut/session/update`,
        headers: {},
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // Handle successful response
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <ContainerWithTitle
      title="What is your height in feet & inches?"
      handleBack={onPrev}
      handleNext={handleSubmit}
      isContinueButton={centimeters ? true : false}
    >
      <div className="input-container flex flex-col gap-8 items-center text-base lg:text-xl">
        <div className="flex gap-2 justify-center lg:text-base text-sm  ">
          <select
            value={feet}
            onChange={handleFeetChange}
            className="bg-white shadow-lg  p-2 px-4  border border-[#2d3d23] rounded"
          >
            <option value="">Feet</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>

          {/* Dropdown for inches */}
          <select
            value={inches}
            onChange={handleInchesChange}
            className="bg-white shadow-lg  p-2 px-4  border border-[#2d3d23] rounded"
          >
            <option value="">Inches</option>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
          </select>
        </div>
      </div>
    </ContainerWithTitle>

    // <div className="outside-container">
    //   <div className="question-container">
    //     <form onSubmit={handleSubmit}>
    //       <div className="question-form">
    //         <div className="form-group">
    //           <div className="question">
    //             <p>What is your height in feet & inches?</p>
    //           </div>
    //           <div className="input-container flex flex-col gap-8 items-center text-base lg:text-xl">
    //             <div className="flex gap-2 justify-center lg:text-base text-sm  ">
    //               <select
    //                 value={feet}
    //                 onChange={handleFeetChange}
    //                 className="bg-white shadow-lg  p-2 px-4  border border-[#2d3d23] rounded"
    //               >
    //                 <option value="">Feet</option>
    //                 <option value="1">1</option>
    //                 <option value="2">2</option>
    //                 <option value="3">3</option>
    //                 <option value="4">4</option>
    //                 <option value="5">5</option>
    //                 <option value="6">6</option>
    //                 <option value="7">7</option>
    //                 <option value="8">8</option>
    //                 <option value="9">9</option>
    //                 <option value="10">10</option>
    //               </select>

    //               {/* Dropdown for inches */}
    //               <select
    //                 value={inches}
    //                 onChange={handleInchesChange}
    //                 className="bg-white shadow-lg  p-2 px-4  border border-[#2d3d23] rounded"
    //               >
    //                 <option value="">Inches</option>
    //                 <option value="0">0</option>
    //                 <option value="1">1</option>
    //                 <option value="2">2</option>
    //                 <option value="3">3</option>
    //                 <option value="4">4</option>
    //                 <option value="5">5</option>
    //                 <option value="6">6</option>
    //                 <option value="7">7</option>
    //                 <option value="8">8</option>
    //                 <option value="9">9</option>
    //                 <option value="10">10</option>
    //                 <option value="11">11</option>
    //               </select>
    //             </div>
    //             {/* Input for height in centimeters */}
    //             {/* <div className="flex gap-5 items-center border-b border-green-900 "> */}
    //             {/* <input
    //                 type="text"
    //                 id="height"
    //                 name="height"
    //                 placeholder="Your height in centimeters"
    //                 className="input "
    //                 value={centimeters}
    //                 readOnly // Make this field read-only, as it's calculated
    //               /> */}
    //             {/* <p>{centimeters}</p>
    //               <label className="text-sm">
    //                 <i>in cm</i>
    //               </label>
    //             </div> */}
    //           </div>
    //           <div className="button-container">
    //             <button type="submit">Next</button>
    //           </div>
    //         </div>
    //       </div>
    //     </form>
    //   </div>
    // </div>
  );
}

export default Height;
