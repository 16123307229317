import React, { useState, useEffect } from "react";
import { useGutContext } from "../context/appContext";
import RadioInput from "../../../containers/input.radio";
import ContainerWithTitle from "../../../containers/title.container";
import ButtonContainer from "../../../containers/button";

const Question9 = ({ onNext, onPrev }) => {
  const {
    payload,
    setPayload,
    currentPage,
    setCurrentPage,
    skipquestion,
    setSkipquestion,
    setErrorMessage,
  } = useGutContext();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(payload?.medical_condition || "");
  }, [currentPage, payload]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value) {
      onNext(value);
      // if (val === "Yes") {
      // } else {
      //   setCurrentPage(currentPage + 2);
      // }
    } else {
      setErrorMessage("Please select an option.");
    }
  };

  const handleRadioChange = (event) => {
    setErrorMessage(null);
    setValue(event.target.value);
    onNext(event.target.value);
  };

  return (
    <ContainerWithTitle
      title="How often are you bothered by gut symptoms, e.g. bloating, acid reflux, constipation, diarohhea?"
      // description="e.g. bloating, reflux, constipation, diarohhea"
      handleBack={onPrev}
      isContinueButton={false}
      noteTitle=""
    >
      <div className="radio-container flex flex-col">
        <RadioInput
          label="Less than once a month"
          value="Less than once a month"
          colorChecked={value === "Less than once a month"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="1-3 times a month"
          value="1-3 times a month"
          colorChecked={value === "1-3 times a month"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="1-2 times a week"
          value="1-2 times a week"
          colorChecked={value === "1-2 times a week"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="3 or more times per week"
          value="3 or more times per week"
          colorChecked={value === "3 or more times per week"}
          onChange={handleRadioChange}
        />
      </div>
      {/* <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer> */}
    </ContainerWithTitle>
  );
};

export default Question9;
